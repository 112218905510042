var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"page-footer"},[_c('div',{staticClass:"page-footer__content-box row mb-tablet-8"},[(_vm.showAdditionalColumn)?_c('div',{staticClass:"page-footer__links col-5 col-tablet-3 col-desktop-3 mb-8"},[(_vm.$route.name === 'home')?_c('router-link',{staticClass:"page-footer__links-item",attrs:{"to":"/#why-vigilex"}},[_vm._v("dlaczego vigilex")]):_vm._e(),_vm._v(" "),(!this.$store.getters['user/isLoggedIn'])?[_c('router-link',{staticClass:"page-footer__links-item",attrs:{"to":"/signup"}},[_vm._v("załóż konto")]),_vm._v(" "),_c('router-link',{staticClass:"page-footer__links-item",attrs:{"to":"/login","rel":"nofollow"}},[_vm._v("zaloguj się")])]:_vm._e(),_vm._v(" "),(_vm.$route.name === 'home')?_c('router-link',{staticClass:"page-footer__links-item",attrs:{"to":"/#about-us"}},[_vm._v("o nas")]):_vm._e()],2):_vm._e(),_vm._v(" "),_c('div',{class:[
        'page-footer__links col-7 mb-8',
        {
          'col-tablet-4 col-desktop-5': _vm.showAdditionalColumn,
          'col-tablet-6 col-desktop-6': !_vm.showAdditionalColumn,
        },
      ]},[_c('button',{staticClass:"page-footer__links-item",on:{"click":function($event){_vm.privacyVisible = true}}},[_vm._v("\n        przetwarzanie danych osobowych\n      ")]),_vm._v(" "),_c('privacy-modal',{attrs:{"visible":_vm.privacyVisible},on:{"close":function($event){_vm.privacyVisible = false},"forceOpen":function($event){_vm.privacyVisible = true}}}),_vm._v(" "),_c('button',{staticClass:"page-footer__links-item mb-tablet-8",on:{"click":function($event){_vm.cookiesVisible = true}}},[_vm._v("\n        ustawienia cookie\n      ")]),_vm._v(" "),_c('cookies-modal',{attrs:{"visible":_vm.cookiesVisible},on:{"close":function($event){_vm.cookiesVisible = false},"forceOpen":function($event){_vm.cookiesVisible = true}}}),_vm._v(" "),_c('button',{staticClass:"page-footer__links-item",on:{"click":function($event){_vm.legalVisible = true}}},[_vm._v("\n        regulamin\n      ")]),_vm._v(" "),_c('legal-modal',{attrs:{"visible":_vm.legalVisible},on:{"close":function($event){_vm.legalVisible = false},"forceOpen":function($event){_vm.legalVisible = true}}}),_vm._v(" "),_c('router-link',{staticClass:"page-footer__links-item",attrs:{"to":"/app/news/leges"}},[_vm._v("serwis")])],1),_vm._v(" "),_c('div',{class:[
        'page-footer__links col-12  mb-12',
        {
          'col-tablet-5 col-desktop-4': _vm.showAdditionalColumn,
          'col-tablet-6 col-desktop-6': !_vm.showAdditionalColumn,
        },
      ]},[_c('a',{staticClass:"page-footer__links-item",attrs:{"href":"mailto:kontakt@monitoringregulacyjny.pl"}},[_vm._v("kontakt@monitoringregulacyjny.pl")]),_vm._v(" "),_c('a',{staticClass:"page-footer__links-item mb-8",attrs:{"href":"tel:+48695975360"}},[_vm._v("+48 695 975 360")]),_vm._v(" "),_vm._m(0)])]),_vm._v(" "),_c('div',{staticClass:"page-footer__copyrights-container"},[_c('span',{staticClass:"page-footer__copyrights"},[_vm._v("© "+_vm._s(new Date().getFullYear())+" vigilex –\n      monitoringregulacyjny.pl")]),_vm._v(" "),_c('span',{staticClass:"page-footer__copyrights"},[_vm._v("Design by belinabrzozowski.pl")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"page-footer__links-item linkedIn-link",attrs:{"href":"https://www.linkedin.com/company/monitoringregulacyjny/","target":"_blank"}},[_vm._v("\n        linkedin "),_c('img',{attrs:{"src":require("./linkedin-icon.svg"),"alt":"LinkedIn"}})])
}]

export { render, staticRenderFns }