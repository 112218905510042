<template>
  <base-modal
    :open="visible"
    url="privacy"
    @close="$emit('close')"
    @forceOpen="$emit('forceOpen')"
  >
    <div class="text-justify">
      <h3 class="heading color-primary privacy-modal__heading">
        Przetwarzanie danych osobowych
      </h3>
      <p>
      <strong>Współadministratorami Twoich danych osobowych</strong> są:<br>
    </p>
    <p>a) Vigilex Sp. z o.o. oraz<br>
    b) Szymon Łajszczak prowadzący działalność gospodarczą pod firmą Kancelaria radcy
      prawnego – dr&nbsp;Szymon Łajszczak <br>
    </p>
    <p>– z&nbsp;siedzibą w&nbsp;Łomiankach (05-092), przy
      ul.&nbsp;Gościńcowej 73.<br>
    </p>
      <p>
        W sprawach związanych z&nbsp;przetwarzaniem danych osobowych możesz
        kontaktować się ze mną pod adresem:
        <a class="legal__link" href="mailto:rodo@monitoringregulacyjny.pl"
          >rodo@monitoringregulacyjny.pl</a
        >
      </p>
      <h4 class="heading--small mb-4">
        W jakim celu i&nbsp;na jakiej podstawie będziemy przetwarzać Twoje dane
        osobowe:
      </h4>
      <ol class="mb-8">
        <li>
          w&nbsp;celu
          <strong
            >obsługi Twojego konta i&nbsp;udostępniania Ci informacji objętych
            monitoringiem</strong
          >
          w&nbsp;oczekiwanym przez Ciebie zakresie oraz informacji na temat
          dostępnych funkcjonalności aplikacji to jest na podstawie art. 6 ust.
          1 lit. f RODO – przetwarzanie jest niezbędne do realizacji prawnie
          uzasadnionych interesów administratora polegających na rozwoju
          prowadzonej działalności gospodarczej;
        </li>
        <li>
          w&nbsp;razie uruchomienia <strong>płatnej usługi</strong> –
          przetwarzanie odbywa się na podstawie art. 6 ust. 1 lit. c RODO –
          w&nbsp;zakresie niezbędnym do
          <strong>zawarcia i&nbsp;wykonywania umowy</strong>;
        </li>
        <li>
          w&nbsp;przypadku
          <strong
            >dochodzenia roszczeń i&nbsp;ochrony przed roszczeniami</strong
          >
          – przetwarzanie odbywa się na podstawie art. 6 ust. 1 lit. f RODO –
          przetwarzanie jest niezbędne do realizacji prawnie uzasadnionych
          interesów administratora.
        </li>
        <li>
          w&nbsp;celu
          <strong>udzielenia odpowiedzi na wysłane do nas pytania</strong>
          na podstawie art. 6 ust. 1 lit. f RODO – przetwarzanie jest niezbędne
          w&nbsp;celach realizacji prawnie uzasadnionych interesów
          administratora polegających na rozwoju prowadzonej działalności
          gospodarczej;
        </li>
      </ol>

      <h4 class="heading--small mb-4">
        Jak długo będziemy przetwarzać Twoje dane:
      </h4>
      Dane przetwarzane są wyłącznie przez okres niezbędny do realizacji celów
      wskazanych powyżej, a&nbsp;po tym czasie przez okres wynikający
      z&nbsp;przepisów prawa tj.:
      <ol>
        <li>
          <strong>rok od likwidacji konta w&nbsp;aplikacji</strong> –
          w&nbsp;zakresie wskazanym w&nbsp;pkt. 1 powyżej;
        </li>
        <li>
          do
          <strong
            >6 lat od zakończenia współpracy lub powstania roszczeń</strong
          >
          w&nbsp;zakresie wskazanym w&nbsp;pkt. 2 i 3 powyżej;
        </li>
        <li>
          <strong>rok od zakończenia korespondencji</strong> – w&nbsp;zakresie
          wskazanym w&nbsp;pkt. 4 powyżej;
        </li>
        <li>
          do <strong>czasu wniesienia sprzeciwu</strong> – w&nbsp;przypadku
          przetwarzania danych na podstawie uzasadnionych interesów
          administratora;
        </li>
      </ol>

      <h4 class="heading--small mb-4">
        Komu mogą zostać udostępnione Twoje dane:
      </h4>
      <p>
        Twoje dane mogą zostać powierzone
        <strong>zaufanym podmiotom dostarczającym na naszą rzecz usługi</strong>
        obejmujące np. obsługę wybranych systemów lub rozwiązań informatycznych,
        księgowości itd. oraz
        <strong>współpracownikom lub wykonawcom</strong> realizującym na naszą
        rzecz prace związane z&nbsp;rozwojem aplikacji.
      </p>

      <p>
        Siedziba niektórych spośród dostawców usług na naszą rzecz może
        znajdować się <strong>poza terenem Unii Europejskiej</strong>. Dotyczy
        to w&nbsp;chwili obecnej takich podmiotów jak PythonAnywhere LLP.
        z&nbsp;siedzibą w&nbsp;Londynie, Amazon Web Services Inc.
        z&nbsp;siedzibą w&nbsp;Seattle oraz Google Inc. z&nbsp;siedzibą
        w&nbsp;Mountain View. Zgodnie z&nbsp;przyjętymi przez te podmioty
        warunkami świadczenia usług i&nbsp;powierzania im danych osobowych,
        zapewniają one poziom ochrony adekwatny z&nbsp;punktu widzenia prawa
        Unii Europejskiej.
      </p>

      <h4 class="heading--small mb-4">
        Jakie prawa przysługują Ci w&nbsp;związku z&nbsp;przetwarzaniem Twoich
        danych:
      </h4>
      <ol>
        <li>
          w&nbsp;każdej chwili możesz żądać dostępu do swoich danych osobowych;
        </li>
        <li>możesz żądać sprostowania swoich danych osobowych;</li>
        <li>
          możesz żądać usunięcia lub ograniczenia przetwarzania danych
          osobowych;
        </li>
        <li>
          możesz wnieść sprzeciw wobec przetwarzania Twoich danych w&nbsp;celu
          realizacji prawnie uzasadnionych interesów administratora;
        </li>
        <li>możesz żądać przeniesienia swoich danych osobowych;</li>
        <li>
          w&nbsp;związku z&nbsp;każdym aspektem przetwarzania swoich danych
          osobowych możesz wnieść skargę do Prezesa Urzędu Ochrony Danych
          Osobowych, jeśli uznasz, że narusza ono przepisy prawa.
        </li>
      </ol>

      <p class="mb-8">
        <strong>Podanie danych jest dobrowolne</strong>, jest jednak
        <strong>konieczne do założenia i&nbsp;obsługi konta</strong>
        w&nbsp;aplikacji vigilex, <strong>do zawarcia umowy</strong> lub do
        <strong>udzielenia odpowiedzi na przesłane nam pytania</strong>. Twoje
        dane nie będą podlegały profilowaniu, a&nbsp;na ich podstawie nie będą
        zapadały automatyczne decyzje.
      </p>
    </div>
  </base-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.privacy-modal__heading {
  font-size: 1.28rem;
  margin-bottom: 1rem;
}
</style>
