<template>
  <base-modal
    :open="visible"
    url="legal"
    @close="$emit('close')"
    @forceOpen="$emit('forceOpen')"
  >
    <div class="Text-justify">
      <h3 class="heading color-primary privacy-modal__heading">
        Regulamin serwisu vigilex
      </h3>
      <p>
        Poniżej znajdziesz podstawowe informacje na temat funkcjonowania serwisu
        vigilex, zasad jego działania i tego, czego możesz się po nim spodziewać
        i od niego oczekiwać. Jeśli jego lektura pozostawi jakiekolwiek pytania
        lub wątpliwości – nie wahaj się do nas napisać na adres
        <a class="legal__link" href="mailto:kontakt@monitoringregulacyjny.pl"
          >kontakt@monitoringregulacyjny.pl</a
        >
        lub z wykorzystaniem zamieszczonego na stronie
        <a
          class="legal__link"
          href="https://www.monitoringregulacyjny.pl/?contact=open"
          >formularza kontaktowego</a
        >. Postaramy się odpowiedzieć na wszelkie nurtujące Cię pytania.<br />
      </p>
      <h4 class="heading--small mb-4">
        Definicje
      </h4>
      <p>
        <strong>Serwis</strong> – aplikacja internetowa vigilex, dostępna pod
        adresem www.monitoringregulacyjny.pl;<br />
      </p>
      <p>
        <strong>Usługodawca</strong> – to ten, kto zapewnia Ci prawidłowe działanie Serwisu. Może nim być:
      </p>
        <p>a) Vigilex sp. z o.o. z siedzibą w Łomiankach (05-092) przy ul. Gościńcowej
          73, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego
          przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIV Wydział Gospodarczy Krajowego
          Rejestru Sądowego pod numerem 0001023017, posiadająca NIP: 1182257856, REGON: 524633981,
          kapitał zakładowy: 6 000 zł (dalej jako „Spółka”);</p>
        <p>b) Szymon Łajszczak prowadzący działalność gospodarczą pod firmą Kancelaria
          radcy prawnego - dr Szymon Łajszczak z siedzibą w Łomiankach (05-092), przy
           ul. Gościńcowej 73 (dalej jako „Kancelaria”);</p>
      <p>
        Jeśli korzystasz z Serwisu nieodpłatnie, Twoim Usługodawcą jest Spółka.
      </p>
      <p>
      Jeśli zawarłeś Odrębną Umowę – Twoim Usługodawcą jest Spółka lub Kancelaria – w zależności od tego, które z nich jest stroną tej umowy.
</p>
      <p>
        <strong>Usługobiorca</strong> – Ty i każda inna osoba korzystająca z
        Serwisu. Jeśli korzystasz z Serwisu w ramach realizacji swoich
        obowiązków zawodowych, Usługobiorcą będzie również organizacja, do
        której należysz;<br />
      </p>
      <p>
        <strong>Regulamin</strong> – regulamin, który właśnie czytasz. Jest on
        również regulaminem świadczenia usług drogą elektroniczną w rozumieniu
        ustawy o świadczeniu usług drogą elektroniczną.<br />
      </p>
      <p>
        <strong>Odrębna Umowa</strong> – umowa zawarta między Tobą
        a Usługodawcą. Reguluje zasady płatnego dostępu do Serwisu.<br />
      </p>

      <h4 class="heading--small mb-4">
        Zakres usług
      </h4>
      <ol>
        <li>
          Działanie Serwisu polega na: <br />a) gromadzeniu wybranych,
          publicznie dostępnych informacji na temat toczących się prac
          legislacyjnych oraz praktyki wybranych regulatorów, <br />b)
          prezentacji tych informacji na stronie internetowej Serwisu, <br />c)
          selekcji wybranych projektów na podstawie kryteriów odnoszących się
          m.in. do ich treści, <br />d) prezentacji wyselekcjonowanych projektów
          w ramach modułów tematycznych, <br />e) wysyłce, z częstotliwością
          wybrana przez Usługobiorcę, raportów obejmujących informacje na temat
          prac nad projektami objętymi modułami tematycznymi wybranymi przez
          Usługobiorcę.
        </li>

        <li>
          Bez konieczności logowania Usługobiorcy mogą zapoznać się w Serwisie z
          najnowszymi informacjami na temat prac nad projektami ustaw i
          rozporządzeń.
        </li>
        <li>
          Usługobiorcy, którzy założą konto w Serwisie, mogą, poza usługami
          wskazanymi w ust. 2, korzystać z wyszukiwarki projektów, a także –
          przez ograniczony czas – testować nieodpłatnie moduły tematyczne, w
          ramach których mogą zapoznać się z wyselekcjonowanymi projektami ustaw
          i rozporządzeń oraz otrzymywać powiązane z nimi cykliczne raporty.
        </li>
      </ol>

      <h4 class="heading--small mb-4">
        Zasady świadczenia usług
      </h4>
      <ol class="mb-8">
        <li>
          Korzystanie z Serwisu, w tym przeglądanie dowolnych treści
          udostępnianych w Serwisie, jest równoznaczne z akceptacją Regulaminu.
          Oznacza to, że w każdym przypadku wpisania przez Usługobiorcę adresu
          Serwisu lub przekierowania go do Serwisu z innej strony lub wiadomości
          e-mail, dochodzi do zawarcia umowy świadczenia usług drogą
          elektroniczną między Usługodawcą i Usługobiorcą. Warunki tej umowy
          określa właśnie Regulamin.
        </li>
        <li>
          Warunki oraz zakres świadczenia usług w ramach płatnego dostępu do Serwisu
          reguluje Odrębna Umowa.
        </li>
        <li>
          W razie niezgodności między postanowieniami Odrębnej Umowy oraz
          Regulaminem – zastosowanie znajdują postanowienia Odrębnej Umowy.
        </li>
        <li>
          Rozwiązanie umowy z Usługodawcą następuje:<br />1) na warunkach
          określonych w Odrębnej Umowie – w przypadkach, o których mowa w ust.
          2;<br />2) po likwidacji konta w serwisie, w razie jego założenia
          przez Usługobiorcę;<br />3) po zakończeniu korzystania z Serwisu bez
          konieczności podejmowania innych dodakowych działań - w przypadkach
          niewymienionych w pkt 1 i 2.
        </li>
        <li>
          Informacje prezentowane w Serwisie nie stanowią pełnego
          odzwierciedlenia czynności prawnych lub faktycznych podejmowanych
          przez organy zaangażowane w prace legislacyjne, a jedynie
          odzwierciedlają wybrane informacje zamieszczone na stronach
          internetowych tych instytucji w sposób publicznie dostępny. Informacje
          te mogą być prezentowane w Serwisie z opóźnieniem, w sposób wypaczony
          lub w ogóle nie podlegać prezentacji ze względu na ich
          niezamieszczenie lub inne nieprzewidziane działania osób
          odpowiedzialnych za zamieszczanie informacji na tych stronach.
          Okoliczności te mogą również wpłynąć na dobór aktów normatywnych
          prezentowanych w ramach poszczególnych modułów.
        </li>
        <li>
          Tekst przepisów projektów zamieszczonych w Serwisie może nie
          odzwierciedlać w pełni tekstu zamieszczonego w wykorzystanych
          źródłach. Tekst ten stanowi wynik odczytu maszynowego i jedynie
          wybiórczo jest poddawany późniejszej redakcji lub korekcie. Dotyczy to
          w szczególności dokumentów poddanych tzw. optycznemu rozpoznawaniu
          znaków (ocr).
        </li>
        <li>
          Usługodawca zastrzega możliwość wystąpienia przerw w działaniu
          wybranych lub wszystkich funkcjonalności Serwisu w następujących
          przypadkach:
          <br />a) w godzinach 23:00-6:00, w razie dokonywania prac
          utrzymaniowych, backupów itp.; <br />b) w razie awarii stron
          internetowych instytucji objętych działaniem Serwisu lub innych
          atypowych lub nieregularnych zjawisk występujących na tych stronach,
          do czasu ustania awarii lub dostosowania kodu źródłowego Serwisu do
          wskazanych zjawisk; <br />c) w razie zastąpienia aktualnych projektów
          stron internetowych instytucji zaangażowanych w prace legislacyjne (w
          tym w szczególności w związku z uruchomieniem strony internetowej
          Sejmu kolejnej kadencji czy uruchomienia nowych wersji innych serwisów
          rządowych lub samorządowych, z których informacje pobierane są do
          Serwisu) do czasu aktualizacji kodu źródłowego Serwisu.
        </li>
        <li>
          Usługodawca nie ponosi odpowiedzialności za jakiekolwiek decyzje lub
          działania Usługobiorcy ani jego klientów podjęte na podstawie
          informacji prezentowanych w Serwisie.
        </li>
      </ol>

      <h4 class="heading--small mb-4">
        Bezpieczeństwo i zagrożenia
      </h4>
      <ol>
        <li>
          Korzystanie z wybranych funkcjonalności Serwisu wymaga od Usługobiorcy
          weryfikacji tożsamości poprzez wprowadzenie loginu (adresu e-mail) i
          hasła.
        </li>
        <li>
          Korzystanie z Serwisu może narazić Usługobiorcę na zagrożenia typowe
          dla usług świadczonych za pośrednictwem aplikacji dostępnych online, w
          szczególności mogą one polegać na próbie przechwycenia od niego danych
          logowania w celu ich wykorzystania w innych serwisach.
        </li>
        <li>
          Zagrożenie to może przybrać formę fałszywych maili, podających się za
          wysłane przez Usługodawcę.
        </li>
        <li>
          Aby zminimalizować takie ryzyko Usługodawca zapewnia bezpieczny
          transfer danych oraz proces autoryzacji, które odbywają się za pomocą
          bezpiecznego protokołu SSL a także nakłada wymóg, aby hasło
          ustanowione przez Usługobiorcę spełniało następujące kryteria: było
          ciągiem przynajmniej ośmiu znaków. Serwis nie zaakceptuje hasła, które
          będzie się składało wyłącznie z cyfr, będzie zbyt podobne do loginu
          lub zbyt popularne (lista takich haseł jest do znalezienia pod
          <a
            class="legal__link"
            href="https://gist.githubusercontent.com/roycewilli
        ams/281ce539915a947a23db17137d91aeb7/raw/abbeab733833304c64e4644e4592f9222f85a38a/pwnedpasswords-v2-top20k.txt"
          >
            tym adresem</a
          >).
        </li>
        <li>
          Ponadto, aby uniknąć, wskazanych zagrożeń, Usługobiorca powinien
          unikać wykorzystywania w Serwisie danych logowania identycznych, jak w
          innych serwisach czy usługach. Usługodawca nie wysyła niezamówionych
          maili, wymagających działania ze strony Usługobiorców, w tym w
          szczególności, podawania przez nich danych logowania. W razie
          jakichkolwiek wątpliwości co do otrzymanej korespondencji – skontaktuj
          się z nami.
        </li>
      </ol>

      <h4 class="heading--small mb-4">
        Wymagania techniczne
      </h4>
      <ol>
        <li>
          Serwis jest dostępny online, nie wymaga instalacji na urządzeniu
          mobilnym ani stacjonarnym. Usługobiorca może z niego korzystać na
          każdym urządzeniu posiadającym odpowiednie oprogramowanie, niezbędne
          do przeglądania stron www. Do prawidłowego korzystania z Serwisu
          niezbędna jest odpowiednia wersja przeglądarki internetowej. Serwis
          powinien wyświetlać się prawidłowo w następujących przeglądarkach:
          Safari (Wersja 12 lub późniejsza), Chrome (wersja 83 lub późniejsza),
          Firefox (wersja 74 lub późniejsza).
        </li>
        <li>
          W przypadku korzystania ze starszej wersji przeglądarki lub innego
          programu niż wymienione powyżej, Serwis może wyświetlać się
          nieprawidłowo lub w ogóle się nie uruchamiać.
        </li>
        <li>
          Aby otrzymywać zamówione raporty lub alerty, Usługobiorca musi
          korzystać z poczty elektronicznej (adesu e-mail).
        </li>
      </ol>
      <h4 class="heading--small mb-4">
        Dane osobowe
      </h4>
      <p>
        Zakładając konto w Serwisie lub kontaktując się z Usługodawcą,
        Usługobiorca przekazuje Usługodawcy swoje dane osobowe. Zasady ich
        przetwarzania
        <a
          class="legal__link"
          href="https://www.monitoringregulacyjny.pl/?privacy=open"
        >
          zostały opisane tu</a
        >.
      </p>
      <h4 class="heading--small mb-4">
        Reklamacje
      </h4>
      <ol>
          <li>
            We wszelkich sprawach związanych z korzystaniem z Serwisu, Usługobiorca może kierować reklamacje do
            Usługodawcy. Reklamacje mogą być kierowane mailowo na adres
            <a class="legal__link" href="mailto:kontakt@monitoringregulacyjny.pl"
            >kontakt@monitoringregulacyjny.pl</a>
            lub z wykorzystaniem zamieszczonego na stronie
            <a class="legal__link" href="https://www.monitoringregulacyjny.pl/?contact=open"
            >formularza kontaktowego</a>.
        </li>
        <li>
          Usługodawca rozpatrzy reklamacje nie później niż w ciągu 30 dni od ich
          złożenia, w formie mailowej – na adres wskazany przez Usługobiorcę.
        </li>
        <li>
          Jeśli reklamacja będzie zawierała braki uniemożliwiające jej
          rozpatrzenie, Usługodawca niezwłocznie skontaktuje się z Usługobiorcą
          w celu uzupełnienia reklamacji. Zwracając się o uzupełnienie
          reklamacji Usługodawca może wskazać termin, po którego upływie
          reklamacja zostanie rozpatrzona na podstawie treści pierwotnego
          zgłoszenia. Termin ten nie może być krótszy niż 7 dni. Do czasu
          uzupełnienia reklamacji, bieg wstrzymuje 30-dniowy termin na
          rozpatrzenie reklamacji.
        </li>
      </ol>
      <h4 class="heading--small mb-4">
        Postanowienia dodatkowe
      </h4>
      <ol>
        <li>
          Regulamin może ulegać zmianie. O ile zmiany te nie będą nakładały na
          Usługobiorców dodatkowych obciążeń, Usługodawca będzie informował o
          zmianach poprzez publikację nowej wersji Regulaminu w Serwisie. Zmiany
          wchodzą w życie z dniem ogłoszenia.
        </li>
        <li>
          Korzystanie z Serwisu po wprowadzeniu zmian w Regulaminie oznacza ich
          akceptację zmian.
        </li>
        <li>
          Jeżeli w związku ze zmianą zakresu usług świadczonych na rzecz Usługobiorcy, na gruncie Odrębnej Umowy,
          dojdzie do zmiany Usługodawcy, zmiana zostanie przeprowadzona automatycznie (z zachowaniem ciągłości
          świadczenia usług), a Usługobiorca nie będzie ponosić z tego tytułu żadnych kosztów.
        </li>
        <li>
          Regulamin w obecnym brzmieniu obowiązuje od dnia 1.06.2023 r.
        </li>
      </ol>
    </div>
  </base-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.privacy-modal__heading {
  font-size: 1.28rem;
  margin-bottom: 1rem;
}
</style>
