<template>
  <footer class="page-footer">
    <div class="page-footer__content-box row mb-tablet-8">
      <div
        v-if="showAdditionalColumn"
        class="page-footer__links col-5 col-tablet-3 col-desktop-3 mb-8"
      >
        <router-link
          v-if="$route.name === 'home'"
          to="/#why-vigilex"
          class="page-footer__links-item"
          >dlaczego vigilex</router-link
        >
        <template v-if="!this.$store.getters['user/isLoggedIn']">
          <router-link to="/signup" class="page-footer__links-item"
            >załóż konto</router-link
          >
          <router-link
            to="/login"
            rel="nofollow"
            class="page-footer__links-item"
            >zaloguj się</router-link
          >
        </template>
        <router-link
          v-if="$route.name === 'home'"
          to="/#about-us"
          class="page-footer__links-item"
          >o nas</router-link
        >
      </div>
      <div
        :class="[
          'page-footer__links col-7 mb-8',
          {
            'col-tablet-4 col-desktop-5': showAdditionalColumn,
            'col-tablet-6 col-desktop-6': !showAdditionalColumn,
          },
        ]"
      >
        <button @click="privacyVisible = true" class="page-footer__links-item">
          przetwarzanie danych osobowych
        </button>
        <privacy-modal
          :visible="privacyVisible"
          @close="privacyVisible = false"
          @forceOpen="privacyVisible = true"
        />
        <button
          @click="cookiesVisible = true"
          class="page-footer__links-item mb-tablet-8"
        >
          ustawienia cookie
        </button>
        <cookies-modal
          :visible="cookiesVisible"
          @close="cookiesVisible = false"
          @forceOpen="cookiesVisible = true"
        />
        <button @click="legalVisible = true" class="page-footer__links-item">
          regulamin
        </button>
        <legal-modal
          :visible="legalVisible"
          @close="legalVisible = false"
          @forceOpen="legalVisible = true"
        />
        <router-link to="/app/news/leges" class="page-footer__links-item"
          >serwis</router-link
        >
      </div>
      <div
        :class="[
          'page-footer__links col-12  mb-12',
          {
            'col-tablet-5 col-desktop-4': showAdditionalColumn,
            'col-tablet-6 col-desktop-6': !showAdditionalColumn,
          },
        ]"
      >
        <a
          href="mailto:kontakt@monitoringregulacyjny.pl"
          class="page-footer__links-item"
          >kontakt@monitoringregulacyjny.pl</a
        >
        <a href="tel:+48695975360" class="page-footer__links-item mb-8"
          >+48 695 975 360</a
        >
        <a
          href="https://www.linkedin.com/company/monitoringregulacyjny/"
          class="page-footer__links-item linkedIn-link"
          target="_blank"
        >
          linkedin <img src="./linkedin-icon.svg" alt="LinkedIn" />
        </a>
      </div>
    </div>

    <div class="page-footer__copyrights-container">
      <span class="page-footer__copyrights"
        >&copy; {{ new Date().getFullYear() }} vigilex –
        monitoringregulacyjny.pl</span
      >
      <span class="page-footer__copyrights">Design by belinabrzozowski.pl</span>
    </div>
  </footer>
</template>

<script>
import CookiesModal from '../CookiesModal';
import PrivacyModal from '../PrivacyModal';
import LegalModal from '../LegalModal';

export default {
  components: {
    PrivacyModal,
    CookiesModal,
    LegalModal,
  },
  data() {
    return {
      privacyVisible: false,
      cookiesVisible: false,
      legalVisible: false,
    };
  },
  computed: {
    showAdditionalColumn: function() {
      return (
        !this.$store.getters['user/isLoggedIn'] || this.$route.name === 'home'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'PageFooter';
</style>
